import React from 'react';
import { useTable,useGlobalFilter } from "react-table";
import classNames from "classnames";
import {SearchFilter} from "../UI";
import styles from "../UI/ResponsiveTable.module.css";
import {formatDateTime} from "../../utils";
import defaultImage from "../../assets/images/defaultImage.png";


const FlaggedTable = ({data}) => {

    const columns = React.useMemo(
        () => [
          {
            Header: "Reporting Profile",
            accessor: "reportingUser",
            Cell: ({cell}) => {
                const reportingUserProfile = cell?.row?.original?.reportingUser?.profilePictureUrl;
                return(
                    <div className="flex items-center">
                        <div
                    key={reportingUserProfile}
                    className="ml-[40px] phone-md:ml-[110px] md:ml-96 lg:ml-0 w-[2rem] h-[2rem] rounded-2xl bg-no-repeat bg-contain bg-center bg-white "
                    style={{
                      backgroundImage: `url("${reportingUserProfile || defaultImage}")`,
                    }}
                  ></div>
                  <p className="ml-2">{cell?.row?.original?.reportingUser?.name}</p>
                  </div>
                )
            } 
          },
          {
            Header: "Comment/Post",
            Cell: ({cell}) => {
                return(
                    <div>
                  <p className="w-1/2 ml-32 phone-md:ml-36 md:w-full md:ml-0">{cell?.row?.original?.flaggedText}</p>
                  <p className="m-2 text-gray-400 text-[10px] mt-[-6px] ml-[1px]">{cell?.row?.original?.courseName}</p>
                  </div>
                )
            }
          },
          {
            Header: "Reported User",
            accessor: "reportedUser",
            Cell: ({cell}) => {
                const reportedUserProfile = cell?.row?.original?.reportedUser?.profilePictureUrl;
                return(
                    <div className="flex items-center">
                        <div
                    key={reportedUserProfile}
                    className=" ml-[50px] phone-md:ml-[110px] md:ml-[400px] lg:ml-0 w-[2rem] h-[2rem] rounded-2xl bg-no-repeat bg-contain bg-center  bg-white "
                    style={{
                      backgroundImage: `url("${reportedUserProfile || defaultImage}")`,
                    }}
                  ></div>
                  <p className="m-2">{cell?.row?.original?.reportedUser?.name}</p>
                  </div>
                )
            }
            
          },
          {
            Header: "Action Taken",
            accessor: "actionTaken",
          },
          {
            Header: "Reporters",
            accessor: "totalCount",
          },
          {
            Header: "Date",
            accessor: "dateCreated",
            Cell: (props) => {
              return (
                formatDateTime(new Date(props?.value), "LLLL do, yyyy. hh:mm a") ||
                "N/A"
              );
            },
          },
        ],
        []
      );
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
      } = useTable({ columns, data }, useGlobalFilter);
    
      const { globalFilter } = state;
    
    return (
      <div className="relative mt-4 mb-12 shadow-md sm:rounded-sm lg:overflow-hidden ">        
      <div className="bg-[#eaeaea] h-[70px] w-full relative top-4 md:top-10 md:overflow-hidden ">
                <div className="bg-[#eaeaea] md:ml-[400px] lg:ml-[500px] xl:ml-[720px] md:mt-[10px] mt-10 md:w-1/2">
            <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} /> 
            </div>   
            </div>

             <div className="grid grid-rows-2 grid-flow-col gap-4 mt-14 ml-6">
         <div className="row-start-1">
         <h2 className="font-semibold text-lg mt-5 ml-3 md:ml-12">Flagged/Reported</h2>
         </div>         
         </div>
           
        <table
        {...getTableProps()}
      
        className={classNames(
          "w-[90%] lg:w-[1200px] m-auto mt-[-70px] md:mt-[-30px] text-sm text-left text-gray-500",
          styles.table
        )}
      >
        <thead className="text-xs text-gray-400 capitalize bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="px-6 py-3">
                  {column.render("Header")}
                  
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="bg-white rounded-2xl shadow-md border p-4">
                {row.cells.map((cell) => {
                  return (
                    <td
                      data-label={cell?.column?.Header}
                      {...cell.getCellProps()}
                      className="px-6 py-4 text-gray-900 text-xs min-w-min"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
        </div>
    )
}

export {FlaggedTable}
