import ExcelJS from 'exceljs'
import { axiosApiInstanceGet } from './axios'
import { API_URL_V1 } from './constants'

/**
 * Set the workbook properties.
 */
function setWorkbookProperties(workbook) {
  workbook.creator = 'Fusion Intelligence'
  workbook.lastModifiedBy = 'Fusion Intelligence'
  workbook.modified = new Date()
  workbook.company = 'Fusion Intelligence'
  workbook.description = `Student Progress Report`
}

/**
 * Set up the worksheet columns.
 */
function setProgressSheetColumns(sheet) {
  sheet.columns = [
    { header: 'S/N', key: 'sn', width: 10 },
    { header: 'Student Name', key: 'studentName', width: 30 },
    { header: 'Email', key: 'email', width: 30 },
    { header: 'Course Title', key: 'courseTitle', width: 30 },
    { header: 'Progress (%)', key: 'percentageProgress', width: 15 },
    { header: 'Date Started', key: 'dateStarted', width: 25 },
    { header: 'Last Login', key: 'lastLoginTime', width: 25 },
  ]
}

/**
 * Add rows to the progress worksheet.
 */
function addProgressRows(sheet, data) {
  data.forEach((item, index) => {
    sheet.addRow({
      sn: index + 1,
      studentName: item.studentName,
      email: item.email,
      courseTitle: item.courseTitle,
      percentageProgress: item.percentageProgress,
      dateStarted: new Date(item.dateStarted).toLocaleString(),
      lastLoginTime: new Date(item.lastLoginTime).toLocaleString(),
    })
  })
}

/**
 * Generate and download the Excel workbook.
 */
async function generateProgressExcelWorkbook(page = 1, perPage = 1000) {
  let allProgress = []

  try {
    const response = await axiosApiInstanceGet(
      `${API_URL_V1}/Subscriptions/get-progress?page=${page}&perPage=${perPage}`,
      true
    )
    allProgress = response?.data?.data?.pagedList || []
  } catch (error) {
    console.error('Error fetching progress data:', error)
    return false
  }

  const workbook = new ExcelJS.Workbook()
  setWorkbookProperties(workbook)

  const sheet = workbook.addWorksheet('Student Progress', {
    pageSetup: { orientation: 'landscape' },
  })

  setProgressSheetColumns(sheet)
  addProgressRows(sheet, allProgress)

  // Generate the Excel file for download
  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = 'Student_Progress_Report.xlsx'
  link.click()
}

export default generateProgressExcelWorkbook
