import React from 'react'
import { useTable } from 'react-table'
import classNames from 'classnames'
import styles from '../UI/ResponsiveTable.module.css'
import { formatDateTime } from '../../utils'

const StudyPercentageTable = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Course Name',
        Cell: ({ cell }) => {
          return (
            <div>
              <p className="text-xs">{cell?.row?.original?.courseTitle}</p>
            </div>
          )
        },
      },
      {
        Header: 'Study Percentage (%)',
        accessor: 'percentCompleted',
      },

      {
        Header: 'Subscription Date',
        accessor: 'dateSubscribed',
        Cell: (props) => {
          return (
            formatDateTime(new Date(props?.value), 'LLLL do, yyyy. hh:mm a') ||
            'N/A'
          )
        },
      },
      {
        Header: 'Last Viewed',
        accessor: 'lastViewedDate',
        Cell: (props) => {
          return (
            formatDateTime(new Date(props?.value), 'LLLL do, yyyy. hh:mm a') ||
            'N/A'
          )
        },
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })

  return (
    <div className="md:flex relative top-[-70px] md:top-[60px] left-64 md:left-[300px]">
      {/* Second Table */}
      <div className="md:border rounded ml-[-270px] mb-[100px] md:mb-[700px] w-[85%]  md:w-[84%]">
        <div className="flex ">
          <p className="font-bold m-5">Study Statistics</p>
        </div>
        <div className="shadow-md sm:rounded-sm">
          <table
            {...getTableProps()}
            className={classNames(
              'w-full h-fit text-sm text-left text-gray-500',
              styles.table
            )}
          >
            <thead className="text-xs capitalize text-gray-400 bg-gray-50">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className="px-6 py-3">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} className="bg-white border-b">
                    {row.cells.map((cell) => {
                      return (
                        <td
                          data-label={cell?.column?.Header}
                          {...cell.getCellProps()}
                          className="px-6 py-4 text-xs text-gray-900 min-w-min "
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          {data.length < 1 && <p className="text-center">No Record Found</p>}
        </div>
      </div>
    </div>
  )
}

export { StudyPercentageTable }
