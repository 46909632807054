import { NavBar, AppFooter, PrivateRoute, Spinner } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRequest } from "../../hooks";
import { LeftOutlined } from "@ant-design/icons";
import { axiosApiInstance, API_URL_V1 } from "../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { generateDiscountSchema, generateDiscountInit } from "../../utils";
import { getDiscountList } from "../../redux/slices";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import Multiselect from "multiselect-dropdownx";
import { getCourseList2, selectCourse } from "../../redux/slices";
import { useEffect } from "react";

const CreateDiscount = () => {
  const { status, setPending, setRejected } = useRequest();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: generateDiscountInit,
    resolver: yupResolver(generateDiscountSchema),
  });

  const { courseList } = useSelector(selectCourse);
  useEffect(() => {
    dispatch(getCourseList2());
  }, [dispatch]);

  const dummyDiscount = ["SingleUse", "MultiUse", "Unlimited"];
  const discountType = ["DiscountByAbsoluteValue", "DiscountByPercentage"];

  const onGenerate = async (values) => {
    const formattedData = {
      ...values,
      courseIds: values?.courseIds?.map((x) => {
        return x?.id;
      }),
    };
    setPending();
    try {
      await axiosApiInstance.post(
        `${API_URL_V1}/DiscountCodes/generate-discount-codes`,
        formattedData
      );
      dispatch(getDiscountList());
      navigate("/discount-code");
    } catch (error) {
      setRejected(error);
    }
  };

  const onFail = (data) => {
    console.log({ ...data } + "submission failed");
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  return (
    <PrivateRoute redirectTo="/discount-code">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <div className="bg-[#eaeaea] h-[80px] w-full relative top-12 overflow-hidden ">
          <div className="flex font-semibold ml-[-10px] md:ml-5 md:mt-[40px]">
            <span className="ml-10 mt-10 md:mt-0">Discounts</span>
            <hr className="w-[70px] mt-16 md:mt-7 h-[2px] ml-[-64px] bg-[#b61046]" />
          </div>
        </div>
        <div className="w-full  relative left-10 top-[90px]  ">
          <div className="grid grid-rows-2 grid-flow-col gap-4">
            <div className="md:row-start-1 md:ml-8">
              <button onClick={() => navigate(-1)}>
                <Link className="text-[#b61046] hover:text-black" to="">
                  <LeftOutlined style={{ fontSize: "12px" }} />
                  <span className="m-[12px]">Back</span>
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="my-24 px-10 border py-4 ml-6 md:ml-20 border-[#d3d3d3] rounded  w-[80%] md:w-[70%] lg:w-[50%]">
          <p className="font-semibold text-lg">Create Discount Code</p>
          <form onSubmit={handleSubmit(onGenerate, onFail)}>
            <div className="flex items-center space-x-6">
              <div className="space-y-2 w-full">
                <label className="text-[#8f8d8d]">Enter discount code</label>
                <input
                  id={`code`}
                  {...register("code")}
                  type="text"
                  className="w-full px-3 py-2 leading-tight border-[#d3d3d3] rounded shadow-lg bg-transparent border-[1px] appearance-none focus:outline-none focus:shadow-outline"
                />
                <output className="block text-left mt-2 text-xs italic text-red-500">
                  {errors?.code?.message}
                </output>
              </div>
              <div className="space-y-2 w-full">
                <label className="text-[#8f8d8d]">Discount Type</label>
                <select
                  id={`discountType`}
                  {...register("discountType")}
                  className="w-full h-fit px-3 py-2 leading-tight placeholder-[#d3d3d3] rounded shadow-lg border-[#d3d3d3] bg-white border-[1px]  focus:outline-none focus:shadow-outline"
                >
                  <option>Select Discount Type</option>
                  {discountType.map((dType, i) => {
                    return (
                      <option key={i} value={dType}>
                        {dType}
                      </option>
                    );
                  })}
                </select>
                <output className="block text-left mt-2 text-xs italic text-red-500">
                  {errors?.discountType?.message}
                </output>
              </div>
            </div>
            <div className="flex items-center space-x-6 ">
              <div className="space-y-2 w-full">
                <label className="text-[#8f8d8d]">Price Cap</label>
                <input
                  id={`priceCap`}
                  {...register("priceCap")}
                  type="text"
                  className="w-full px-3 py-2 leading-tight border-[#d3d3d3] placeholder-[#d3d3d3] rounded shadow-lg bg-transparent border-[1px] appearance-none focus:outline-none focus:shadow-outline"
                  placeholder="NGN"
                />
                <output className="block text-left mt-2 text-xs italic text-red-500">
                  {errors?.priceCap?.message}
                </output>
              </div>
              <div className="space-y-2 w-full">
                <label className="text-[#8f8d8d]">Value</label>
                <input
                  id={`value`}
                  {...register("value")}
                  type="text"
                  className="w-full px-3 py-2 leading-tight border-[#d3d3d3] rounded shadow-lg bg-transparent border-[1px] appearance-none focus:outline-none focus:shadow-outline"
                />
                <output className="block text-left mt-2 text-xs italic text-red-500">
                  {errors?.value?.message}
                </output>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <div className="space-y-2 w-full">
                <label className="text-[#8f8d8d]">Discount Validity</label>
                <select
                  {...register("discountValidity")}
                  className="w-full h-fit px-3 py-2 leading-tight rounded shadow-lg border-[#d3d3d3] bg-white border-[1px]  focus:outline-none focus:shadow-outline"
                >
                  <option>Select Discount</option>
                  {dummyDiscount.map((discountType, i) => {
                    return (
                      <option key={i} value={discountType}>
                        {discountType}
                      </option>
                    );
                  })}
                </select>
                <output className="block text-left mt-2 text-xs italic text-red-500">
                  {errors?.discountValidity?.message}
                </output>
              </div>
              {(watch("discountValidity") === "MultiUse" ||
                watch("discountValidity") === "Unlimited") && (
                <div className="space-y-2 w-full">
                  <label className="text-[#8f8d8d]">Number of Uses</label>
                  <input
                    id={`limitCount`}
                    {...register("limitCount")}
                    type="text"
                    className="w-full px-3 py-2 leading-tight border-[#d3d3d3] rounded shadow-lg bg-transparent border-[1px] appearance-none focus:outline-none focus:shadow-outline"
                  />
                  <output className="block text-center mt-2 text-xs italic text-red-500">
                    {errors?.limitCount?.message}
                  </output>
                </div>
              )}
            </div>
            <div className="flex items-center space-x-6">
              <div className="space-y-2 w-full">
                <label className="text-[#8f8d8d]">Expiry Date</label>
                <DatePicker
                  disabledDate={disabledDate}
                  onChange={(date, dateString) => {
                    setValue(`expiryDate`, new Date(dateString).toISOString());
                  }}
                  className="w-full border border-solid border-gray-400 rounded-2xl p-3 mt-2 mb-4 shadow-md"
                />
                <output className="block text-left mt-2 text-xs italic text-red-500">
                  {errors?.discountValidity?.message}
                </output>
              </div>
            </div>
            {watch("isCourseSpecific") === true && (
              <div className="w-full space-y-2">
                <label className="text-[#8f8d8d]">Select Course</label>
                <Multiselect
                  displayValue="name"
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  selectedValues={getValues(`courseIds`)}
                  className="mb-24"
                  style={{
                    option: { background: "#b61046", color: "white" },
                    chips: { background: "#b61046" },
                  }}
                  onSelect={(selectedList) => {
                    setValue(`courseIds`, selectedList);
                  }}
                  options={courseList?.map((x) => {
                    return { name: x?.name, id: x?.courseId };
                  })}
                  // selectionLimit={10}
                />
              </div>
            )}

            <div>
              <input
                type="checkbox"
                id={`isCourseSpecific`}
                {...register("isCourseSpecific")}
                className="text-[#b61046] rounded-md accent-[#b61046] ring-[#b61046]"
              />
              <label htmlFor="checkbox">Course Specific</label>
            </div>

            <div className="flex justify-end px-6 py-3">
              <button
                type="submit"
                className="bg-[#b61046] flex items-center text-white rounded-md px-6 py-3"
              >
                Create Code
                <Spinner
                  className="w-3 h-3 text-white ml-1 fill-pink-400"
                  status={status}
                />
              </button>
            </div>
          </form>
        </div>
        <AppFooter />
      </div>
    </PrivateRoute>
  );
};

export { CreateDiscount };
