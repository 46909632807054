import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  NavBar,
  AppFooter,
  Loading,
  Error,
  Success,
  Pagination,
  PrivateRoute,
} from '../../components'
import { selectStudent, getProgressList } from '../../redux/slices'
import clamp from 'lodash/clamp'
import { ProgressTable } from '../../components/Students/ProgressTable'
import generateProgressExcelWorkbook from '../../utils/generateProgressExcelWorkbook'

const ProgressPage = () => {
  const dispatch = useDispatch()
  const {
    page,
    totalPages,
    progressList,
    progressListErr,
    progressListStatus,
  } = useSelector(selectStudent)

  const getList = useCallback(
    (force = true, page) => {
      dispatch(getProgressList({ force, page, perPage: 10 }))
    },
    [dispatch]
  )

  useEffect(() => {
    getList(true, 1)
  }, [getList])

  return (
    <PrivateRoute redirectTo="/progress">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <section>
          <Loading status={progressListStatus} />
          <Error
            retry={getList}
            err={progressListErr}
            status={progressListStatus}
          />
          <Success status={progressListStatus}>
            <ProgressTable data={progressList} />
            <div className="flex items-center justify-center">
              <button
                onClick={() => generateProgressExcelWorkbook()}
                className="bg-[#b61046] text-white px-4 py-2 rounded mb-10"
              >
                Download Progress Report
              </button>
            </div>
          </Success>
          <Success status={progressListStatus}>
            <div className="text-center mt-[-10px] md:mb-16">
              <Pagination
                prev={() => getList(false, clamp(page - 1, 1, totalPages))}
                next={() => getList(false, clamp(page + 1, 1, totalPages))}
                goTo={(page) => getList(false, page)}
                currentPage={page}
                totalPages={totalPages}
                isFirst={page <= 1}
                isLast={page >= totalPages}
              />
            </div>
          </Success>
        </section>
        <AppFooter />
      </div>
    </PrivateRoute>
  )
}

export { ProgressPage }
