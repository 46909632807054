import React from 'react'
import { useTable, useGlobalFilter, useSortBy } from 'react-table' // Import useSortBy
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { SearchFilter } from '../UI'
import styles from '../UI/ResponsiveTable.module.css'
import { formatDateTime } from '../../utils'

const MailingListTable = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => value || 'N/A',
      },
      {
        Header: 'Subscription Status',
        accessor: 'subscriptionStatus',
      },
      {
        Header: 'Date Created',
        accessor: 'subscriptionDate',
        Cell: (props) => {
          return (
            formatDateTime(new Date(props?.value), 'LLLL do, yyyy. hh:mm a') ||
            'N/A'
          )
        },
      },
    ],
    []
  )

  // Include useSortBy hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy)

  const { globalFilter } = state

  return (
    <div className="relative mt-4 mb-12 shadow-md sm:rounded-sm lg:overflow-hidden">
      <div className="bg-[#eaeaea] h-[90px] w-full relative top-4 md:top-12 md:overflow-hidden">
        <div className="flex font-semibold ml-[-10px] md:ml-5 md:mt-[40px]">
          <span className="ml-10 mt-10 md:mt-0">
            <Link
              className="text-black hover:text-[#b61046]"
              to="/activeStudents"
            >
              Active Students
            </Link>
          </span>
          <span className="ml-10 mt-10 md:mt-0">
            <Link
              className="text-black hover:text-[#b61046]"
              to="/deactivatedStudents"
            >
              Deactivated Students
            </Link>
          </span>
          <span className="ml-10 mt-10 md:mt-0">
            <Link
              className="text-black hover:text-[#b61046]"
              to="/student-progress"
            >
              Progress Table{' '}
            </Link>
          </span>
          <span className="ml-10 mt-10 md:mt-0">Mailing List</span>
          <hr className="w-[90px] mt-16 md:mt-11 h-[2px] ml-[-80px] bg-[#b61046]" />
          <span className="ml-10 mt-10 md:mt-0">
            <Link
              className="text-black hover:text-[#b61046]"
              to="/referral-list"
            >
              Referral List
            </Link>
          </span>
        </div>
        <div className="bg-[#eaeaea] md:ml-[400px] lg:ml-[720px] md:mt-[-70px] md:w-1/2">
          <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>

      <div className="grid grid-rows-2 grid-flow-col gap-4 mt-14 ml-6">
        <div className="row-start-1">
          <h2 className="font-semibold text-lg mt-10 md:mt-5 ml-3 md:ml-12">
            Mailing List
          </h2>
        </div>
      </div>

      <table
        {...getTableProps()}
        className={classNames(
          'w-[90%] lg:w-[1200px] m-auto mt-[-70px] mb-10 md:mt-[-30px] text-sm text-left text-gray-500',
          styles.table
        )}
      >
        <thead className="text-xs text-gray-400 capitalize bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())} // Add sorting props
                  className="px-6 py-3"
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className="bg-white rounded-2xl shadow-md border p-4"
              >
                {row.cells.map((cell) => (
                  <td
                    data-label={cell?.column?.Header}
                    {...cell.getCellProps()}
                    className="px-6 py-4 text-gray-900 text-xs min-w-min"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export { MailingListTable }
