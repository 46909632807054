import React,{useEffect} from "react";
import { NavBar, AppFooter,Error,Spinner,PrivateRoute} from "../../components";
import {getAllRoles,getSingleAdmin,selectAdmin,selectAuth} from "../../redux/slices";
import {useRequest} from "../../hooks";
import {updateAdminSchema,axiosApiInstance,API_URL_V1} from "../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import {useSelector,useDispatch} from "react-redux"
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";


const ProfileFormPage = () => {
  const { err, status, setPending, setRejected } = useRequest();

  const {roles,singleAdmin} = useSelector(selectAdmin);
  const {userId} = useSelector(selectAuth);

  const dispatch = useDispatch();
  
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getSingleAdmin(userId));
  },[dispatch,userId])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues:{
        firstName:singleAdmin.firstName || "",
        lastName:singleAdmin.lastName || "",
        email:singleAdmin.email || "",
        role:singleAdmin.roleName || "",
    },
    resolver: yupResolver(updateAdminSchema),
  });

  const onUpdate = async (values) => {
    const body = {
    firstName: values.firstName || singleAdmin.firstName,
    lastName: values.lastName || singleAdmin.lastName,
    email: values.email || singleAdmin.email,
    role: values.role || singleAdmin.role,
    }

    setPending();

    try {
      await axiosApiInstance.put(`${API_URL_V1}/Admins/update-admin?adminId=${userId}`,body);
      dispatch(getSingleAdmin(userId));
      navigate("/profile");
    } catch (error) {
      setRejected(error);
    }
  };

    return (
      <PrivateRoute redirectTo="/editProfile">
        <div >
        <NavBar/>
        <div className="bg-[#eaeaea] h-[50px] w-full relative top-12 overflow-hidden ">       
        </div>
        <div className="w-full h-full  top-[90px] ">
        <div className="grid grid-rows-2 grid-flow-col gap-4">
        <div className="row-start-1">
         <h2 className="font-semibold text-lg mt-3 ml-3 md:ml-12">Edit Profile</h2>
         </div>  
        </div>

{/* Form Starts Here */}
<div className="container mx-auto">
			<div className="flex justify-center px-6 my-12">
				<div className="w-full md:w-11/12 flex">
					
					<div
						className="w-full h-auto hidden md:block md:w-5/12  md:ml-[-25px] md:-mt-4 lg:mt-[80px] ">
                            <p className="font-semibold text-lg text-zinc-700">Basic Information</p>
                    </div>
					
					<div className="w-full md:w-7/12 bg-white p-5 rounded-lg md:rounded-l-none ml-[-30px] md:ml-[-220px] md:mt-[20px] mt-[-120px]">
                    <p className="font-semibold text-lg text-zinc-700 md:ml-8 ml-12 md:hidden">Basic Information</p>
                    <Error err={err} status={status} />
						<form className="px-8 pt-6 pb-8 mb-4 bg-white rounded" onSubmit={handleSubmit(onUpdate)}>
							<div className="mb-12 md:flex md:justify-between">
								<div className="mb-4 md:mr-2 md:mb-0">
									<label className="block mb-2 text-sm font-medium text-gray-400" htmlFor={`firstName`}>
										First Name
									</label>
									<input
										className="w-[300px] px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        id={`firstName`}
                                        {...register("firstName")}
										type="text"
										placeholder="First Name"
									/>
                                    <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                        {errors?.firstName?.message}
                      </output>
								</div>
								<div className="md:ml-2">
									<label className="block mb-2 text-sm font-medium text-gray-400" htmlFor={`lastName`}>
										Last Name
									</label>
									<input
										className="w-[300px] px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        id={`lastName`}
                                        {...register("lastName")}
										type="text"
										placeholder="Last Name"
									/>
                                     <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                        {errors?.lastName?.message}
                      </output>
								</div>
							</div>
							
							<div className="mb-4 md:flex md:justify-between">
								<div className="mb-4 md:mr-2 md:mb-0">
									<label className="block mb-2 text-sm font-medium text-gray-400" htmlFor={`email`}>
										Email Address
									</label>
									<input
										className="w-[300px] px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        id={`email`}
                                        {...register("email")}
										type="text"
										placeholder="admin@mail.com"
									/>
                                     <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                        {errors?.email?.message}
                      </output>
								</div>
								<div className="md:ml-2 ">
									<label className="block mb-2 text-sm font-medium text-gray-400" htmlFor={`role`}>
										Admin Role
									</label>
									<select id={`role`} {...register("role")} className="w-[300px] px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border  rounded shadow appearance-none focus:outline-none focus:shadow-outline">
                                    <option value="">Select Role</option>
                            {roles.map((singleRole,index)=>{
                              return(
                                <option key={index} value={singleRole}>
                              {singleRole}
                            </option>
                              );
                            })}
                            </select>
                            <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                        {errors?.role?.message}
                      </output>
								</div>
							</div>

                            <div className="relative top-[-20px]  md:top-[-300px] md:ml-[-110px]">
         <button className="md:ml-[575px] text-white mt-[20px] hover:border hover:border-[#B61046] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]" type="submit" >Save Changes
         <Spinner
                  className="!w-3  !h-3 ml-[120px] text-white fill-pink-400"
                  status={status}
                />
         </button>
        </div>

						
						</form>

					</div>
				</div>
			</div>
		</div>
        {/* Form Ends Here */}
        </div>
        <AppFooter/>
        </div>
        </PrivateRoute>
    )
}

export {ProfileFormPage}
