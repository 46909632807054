import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL_V1, axiosApiInstanceGet, FETCH_STATUS } from '../../utils'

export const getStudent = createAsyncThunk(
  'getStudent',
  async (studentId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Students/get-student-by-id?studentId=${studentId}`
      )
      return fulfillWithValue(response?.data?.data)
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const getStudentList = createAsyncThunk(
  'getStudentList',
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      }
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Students/list-all-students-by-status?status=true&page=${page}&perPage=${pageSize}`,
        force
      )

      return fulfillWithValue(response?.data?.data)
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const getDeactivatedStudentList = createAsyncThunk(
  'getDeactivatedStudentList',
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      }
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Students/list-all-students-by-status?status=false&page=${page}&perPage=${pageSize}`,
        force
      )

      return fulfillWithValue(response?.data?.data)
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const getFlaggedList = createAsyncThunk(
  'getFlaggedList',
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      }
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/FlaggedReports/list-all-reports?page=${page}&perPage=${pageSize}`,
        force
      )

      return fulfillWithValue(response?.data?.data)
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const getReportList = createAsyncThunk(
  'getReportList',
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { studentId, force, page } = {
        force: false,
        page: 1,
        ...config,
      }
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/FlaggedReports/list-all-reports-against-user?studentId=${studentId}&page=${page}&perPage=${pageSize}`,
        force
      )

      return fulfillWithValue(response?.data?.data)
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const getStudentPercent = createAsyncThunk(
  'getStudentPercent',
  async (studentId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Subscriptions/get-students-learning-report?studentId=${studentId}`
      )
      return fulfillWithValue(response?.data?.data)
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const getStudentDashboardStat = createAsyncThunk(
  'getStudentDashboardStat',
  async ({ force, studentId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Analytics/get-student-stats?studentId=${studentId}`,
        force
      )
      return fulfillWithValue(response?.data?.data)
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const getProgressList = createAsyncThunk(
  'getProgressList',
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page, perPage } = {
        force: false,
        page: 1,
        perPage: 10,
        ...config,
      }
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Subscriptions/get-progress?page=${page}&perPage=${perPage}`,
        force
      )

      return fulfillWithValue(response?.data?.data)
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

const pageSize = 10
const initialState = {
  page: 0,
  totalPages: 1,
  student: {},
  studentErr: '',
  studentStatus: FETCH_STATUS.IDLE,
  studentList: [],
  studentListErr: '',
  studentListStatus: FETCH_STATUS.IDLE,
  studentPercentList: [],
  studentPercentListErr: '',
  studentPercentListStatus: FETCH_STATUS.IDLE,
  deactivatedStudent: [],
  deactivatedStudentErr: '',
  deactivatedStudentStatus: FETCH_STATUS.IDLE,
  flaggedList: [],
  flaggedListErr: '',
  flaggedListStatus: FETCH_STATUS.IDLE,
  reportList: [],
  reportListErr: '',
  reportListStatus: FETCH_STATUS.IDLE,
  studentDashboard: {},
  studentDashboardErr: '',
  studentDashboardStatus: FETCH_STATUS.IDLE,
  progressList: [],
  progressListErr: '',
  progressListStatus: FETCH_STATUS.IDLE,
}

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getStudent.pending, (state) => {
        state.studentStatus = FETCH_STATUS.PENDING
        state.studentErr = ''
      })
      .addCase(getStudent.fulfilled, (state, action) => {
        state.studentStatus = FETCH_STATUS.RESOLVED
        state.student = action.payload
        state.studentErr = ''
      })
      .addCase(getStudent.rejected, (state, action) => {
        state.studentStatus = FETCH_STATUS.REJECTED
        state.studentErr = action.payload
      })
      .addCase(getStudentPercent.pending, (state) => {
        state.studentPercentListStatus = FETCH_STATUS.PENDING
        state.studentPercentListErr = ''
      })
      .addCase(getStudentPercent.fulfilled, (state, action) => {
        state.studentPercentListStatus = FETCH_STATUS.RESOLVED
        state.studentPercentList = action.payload
        state.studentPercentListErr = ''
      })
      .addCase(getStudentPercent.rejected, (state, action) => {
        state.studentPercentListStatus = FETCH_STATUS.REJECTED
        state.studentPercentListErr = action.payload
      })
      .addCase(getStudentList.pending, (state) => {
        state.studentListStatus = FETCH_STATUS.PENDING
        state.studentListErr = ''
      })
      .addCase(getStudentList.fulfilled, (state, action) => {
        state.studentListStatus = FETCH_STATUS.RESOLVED
        const { metaData, pagedList } = action.payload
        state.studentList = pagedList
        state.studentListErr = ''
        state.page = metaData?.page
        state.totalPages = metaData?.totalPages
      })
      .addCase(getStudentList.rejected, (state, action) => {
        state.studentListStatus = FETCH_STATUS.REJECTED
        state.studentListErr = action.payload
      })
      .addCase(getDeactivatedStudentList.pending, (state) => {
        state.deactivatedStudentStatus = FETCH_STATUS.PENDING
        state.deactivatedStudentErr = ''
      })
      .addCase(getDeactivatedStudentList.fulfilled, (state, action) => {
        state.deactivatedStudentStatus = FETCH_STATUS.RESOLVED
        const { metaData, pagedList } = action.payload
        state.deactivatedStudent = pagedList
        state.deactivatedStudentErr = ''
        state.page = metaData?.page
        state.totalPages = metaData?.totalPages
      })
      .addCase(getDeactivatedStudentList.rejected, (state, action) => {
        state.deactivatedStudentStatus = FETCH_STATUS.REJECTED
        state.deactivatedStudentErr = action.payload
      })
      .addCase(getFlaggedList.pending, (state) => {
        state.flaggedListStatus = FETCH_STATUS.PENDING
        state.flaggedListErr = ''
      })
      .addCase(getFlaggedList.fulfilled, (state, action) => {
        state.flaggedListStatus = FETCH_STATUS.RESOLVED
        const { metaData, pagedList } = action.payload
        state.flaggedList = pagedList
        state.flaggedListErr = ''
        state.page = metaData?.page
        state.totalPages = metaData?.totalPages
      })
      .addCase(getFlaggedList.rejected, (state, action) => {
        state.flaggedListStatus = FETCH_STATUS.REJECTED
        state.flaggedListErr = action.payload
      })
      .addCase(getReportList.pending, (state) => {
        state.reportListStatus = FETCH_STATUS.PENDING
        state.reportListErr = ''
      })
      .addCase(getReportList.fulfilled, (state, action) => {
        state.reportListStatus = FETCH_STATUS.RESOLVED
        const { metaData, pagedList } = action.payload
        state.reportList = pagedList
        state.reportListErr = ''
        state.page = metaData?.page
        state.totalPages = metaData?.totalPages
      })
      .addCase(getReportList.rejected, (state, action) => {
        state.reportListStatus = FETCH_STATUS.REJECTED
        state.reportListErr = action.payload
      })
      .addCase(getStudentDashboardStat.pending, (state) => {
        state.studentDashboardStatus = FETCH_STATUS.PENDING
        state.studentDashboardErr = ''
      })
      .addCase(getStudentDashboardStat.fulfilled, (state, action) => {
        state.studentDashboardStatus = FETCH_STATUS.RESOLVED
        state.studentDashboard = action.payload
        state.studentDashboardErr = ''
      })
      .addCase(getStudentDashboardStat.rejected, (state, action) => {
        state.studentDashboardStatus = FETCH_STATUS.REJECTED
        state.studentDashboardErr = action.payload
      })
      .addCase(getProgressList.pending, (state) => {
        state.progressListStatus = FETCH_STATUS.PENDING
        state.progressListErr = ''
      })
      .addCase(getProgressList.fulfilled, (state, action) => {
        state.progressListStatus = FETCH_STATUS.RESOLVED
        const { metaData, pagedList } = action.payload
        state.progressList = pagedList
        state.progressListErr = ''
        state.page = metaData?.page
        state.totalPages = metaData?.totalPages
      })
      .addCase(getProgressList.rejected, (state, action) => {
        state.progressListStatus = FETCH_STATUS.REJECTED
        state.progressListErr = action.payload
      })
  },
})

export const studentReducer = studentSlice.reducer
export const selectStudent = (state) => state.student
